import { makeStyles } from "@mui/styles";

const useProfileStyles = makeStyles((theme) => ({
  root: {},
  accountAvatar: {
    fontSize: `100px !important`,
  },
}));

export default useProfileStyles;
