import * as React from "react";

import useHeaderStyles from "./index.styles";
import ThemeSwitchButton from "../ui/ThemeSwitchButton";
import HeaderLeftDrawer from "./HeaderLeftDrawer";
import Typography from "@mui/material/Typography";
import HeaderMenu from "./HeaderMenu";

const Header = (props) => {
  const { title = "HOPE" } = props;
  const classes = useHeaderStyles();

  return (
    <header className={classes.root}>
      <HeaderLeftDrawer />
      <Typography variant={"h2"} className={classes.companyTitle}>
        {title}
      </Typography>
      <HeaderMenu />
      <ThemeSwitchButton
        customClasses={{
          root: classes.themeBtnRoot,
        }}
      />
    </header>
  );
};

export default Header;
