import * as React from "react";
import { Routes, Route, Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./utils/AuthProvider";

import Login from "./pages/Login";
import ThemeContext from "./components/ui/ThemeSwitchButton/index.context";
import { THEME_NAMES, THEMES_MAP } from "./utils/constants";
import Reports from "./pages/Reports";
import Signup from "./pages/Signup";
import Profile from "./pages/Profile";
import Landing from "./pages/Landing";

function App() {
  const [currentTheme, setCurrentTheme] = React.useState(THEME_NAMES.DARK);

  const theme = React.useMemo(() => THEMES_MAP[currentTheme], [currentTheme]);

  const setTheme = React.useCallback((themeName = "dark") => {
    if (!Object.values(THEME_NAMES).includes(themeName)) return;
    localStorage.setItem("theme", themeName);
    setCurrentTheme(themeName);
  }, []);

  const toggleTheme = React.useCallback(() => {
    setTheme(
      currentTheme === THEME_NAMES.LIGHT ? THEME_NAMES.DARK : THEME_NAMES.LIGHT
    );
  }, [currentTheme, setTheme]);

  const themeContextValue = React.useMemo(
    () => ({
      toggleTheme,
      currentTheme,
      setTheme,
    }),
    [toggleTheme, currentTheme, setTheme]
  );

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path={"/reports"} element={<Reports />} />
            <Route path={"/profile"} element={<Profile />} />
            {/*<AuthenticatedRoutes />*/}
            <Route path="about" element={<About />} />
            <Route path="/" element={<Landing />} />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

function About() {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>That feels like an existential question, don't you think?</p>
      </main>
      <nav>
        <Link to="/login">Login</Link>
      </nav>
    </>
  );
}

export default App;
