import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

import useThemeSwitchButtonStyles from "./index.styles";
import ThemeContext from "./index.context";
import { THEME_NAMES } from "../../../utils/constants";

const ThemeSwitchButton = ({ customClasses = {} }) => {
  const classes = useThemeSwitchButtonStyles();
  const themeContext = React.useContext(ThemeContext);

  const Icon = React.useMemo(() => {
    switch (themeContext.currentTheme) {
      case THEME_NAMES.LIGHT:
        return DarkModeOutlinedIcon;
      case THEME_NAMES.DARK:
      default:
        return LightModeOutlinedIcon;
    }
  }, [themeContext.currentTheme]);

  return (
    <Box className={`${classes.root} ${customClasses.root}`}>
      <IconButton
        aria-label="Switch Theme"
        color="primary"
        onClick={themeContext.toggleTheme}
      >
        <Icon />
      </IconButton>
    </Box>
  );
};

ThemeSwitchButton.defaultProps = {
  customClasses: {
    root: "",
    iconButton: "",
    icon: "",
    darkModeIcon: "",
    lightModeIcon: "",
  },
};

export default ThemeSwitchButton;
