import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

import { getMetaThemeColor } from "../../utils/theme";

const useSplashScreenStyles = makeStyles(({ palette, cubicBezier }) => ({
  root: {
    position: "fixed",
    inset: 0,
    bottom: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    zIndex: 999999,
    background: getMetaThemeColor(palette.mode),
    display: "grid",
    placeItems: "center",
  },
  hopeTitle: {
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "block",
      height: "3px",
      width: "50%",
      borderRadius: 5,
      background: palette.text.primary,
      animation: `backAndForth 1s ${cubicBezier.quadratic} infinite`,
    },
  },

  "@global @keyframes backAndForth": {
    "0%": {
      transform: "translate(-50%, 0) scaleX(0)",
    },
    "25%": {
      transform: "translate(50%, 0) scaleX(1)",
    },
    "50%": {
      transform: "translate(150%, 0) scaleX(0)",
    },
    "75%": {
      transform: "translate(50%, 0) scaleX(1)",
    },
    "100%": {
      transform: "translate(-50%, 0) scaleX(0)",
    },
  },

  "@global @keyframes slowDisappear": {
    "0%": {
      background: getMetaThemeColor(palette.mode),
      backdropFilter: "blur(0px)",
    },
    "99%": {
      background: alpha(getMetaThemeColor(palette.mode), 0.72),
      backdropFilter: "blur(20px)",
    },
    "100%": {
      display: "none",
    },
  },
}));

export default useSplashScreenStyles;
