import { makeStyles } from "@mui/styles";

const useLandingPageStyles = makeStyles(
  ({ palette, breakpoints, cubicBezier }) => ({
    root: {},
    hero: {
      height: "calc(100vh - 54px)",
    },
    heroContent: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      background: palette.background.default,
    },
    leftContentWrapper: {
      height: "100%",
      width: "70%",
      filter: `drop-shadow(17px 0px 0px ${palette.text.secondary})`,
    },
    heroLeftContent: {
      textAlign: "center",
      clipPath: "polygon(0 0, 100% 0, 75% 100%, 0 100%)",
      height: "100%",
      width: "100%",
      paddingRight: "400px",
      background: palette.background.default,
      display: "grid",
      placeContent: "center",
    },
    heroTitle: {
      fontSize: "110px !important",
    },
    heroDesc: {
      height: "min-content",
    },
    rightContent: {
      flex: 1,
      display: "grid",
      placeItems: "center",
      height: "100%",
      position: "relative",
    },
    dProp: {
      display: "inline-block",
      isolation: "isolate",
      scale: 3,
      translate: "0 -8px",
    },
    graphIcon: {
      scale: 20,
    },
    rupeeIcon: {
      position: "absolute",
      top: "10%",
      left: "16%",
      scale: 0,
      rotate: "-23deg",
      animation: `slowAppearIcons .3s .1s ${cubicBezier.outBackBack} forwards`,
    },
    bitcoinIcon: {
      position: "absolute",
      bottom: "10%",
      right: "16%",
      scale: 0,
      rotate: "25deg",
      animation: `slowAppearIcons .3s .15s ${cubicBezier.outBackBack} forwards`,
    },
    carIcon: {
      position: "absolute",
      top: "10%",
      right: "16%",
      scale: 0,
      rotate: "25deg",
      animation: `slowAppearIcons .3s .2s ${cubicBezier.outBackBack} forwards`,
    },
    saveIcon: {
      position: "absolute",
      bottom: "10%",
      left: "16%",
      scale: 0,
      rotate: "-25deg",
      animation: `slowAppearIcons .3s .25s ${cubicBezier.outBackBack} forwards`,
    },
    bankIcon: {
      position: "absolute",
      bottom: "50%",
      left: "-10%",
      scale: 0,
      rotate: "-25deg",
      animation: `slowAppearIcons .3s .3s ${cubicBezier.outBackBack} forwards`,
    },
    hopeDetails: {
      borderTop: `17px solid ${palette.text.secondary}`,
      height: "50vh",
    },

    [breakpoints.up("md")]: {
      "@global @keyframes slowAppearIcons": {
        from: {
          scale: 0,
        },
        to: {
          scale: 2,
        },
      },
    },
  })
);

export default useLandingPageStyles;
