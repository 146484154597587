import React from "react";
import { auth } from "../firebase";
import SplashScreen from "../components/SplashScreen";
import ThemeContext from "../components/ui/ThemeSwitchButton/index.context";

const AuthContext = React.createContext();

function useAuth() {
  return React.useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const themeContext = React.useContext(ThemeContext);

  React.useLayoutEffect(() => {
    const theme = localStorage.getItem("theme");
    themeContext.setTheme(theme);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  }, []);

  const signup = React.useCallback((email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  }, []);

  const login = React.useCallback((email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  }, []);

  const logout = React.useCallback(() => {
    return auth.signOut();
  }, []);

  const contextValue = React.useMemo(
    () => ({
      currentUser,
      signup,
      login,
      logout,
    }),
    [currentUser, signup, login, logout]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
      {loading && <SplashScreen />}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
