import React from "react";

import useLandingStyles from "./index.styles";
import Header from "../../components/Header";
import LandingPage from "../../components/LandingPage";

const Landing = (props) => {
  const classes = useLandingStyles();

  return (
    <main className={classes.root}>
      <Header />
      <LandingPage />
    </main>
  );
};

export default Landing;
