import { lightTheme, darkTheme } from "./theme";

const THEME_NAMES = {
  LIGHT: "light",
  DARK: "dark",
};

const THEMES_MAP = {
  [THEME_NAMES.LIGHT]: lightTheme,
  [THEME_NAMES.DARK]: darkTheme,
};

const REGEX = {
  EMAIL: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
};

const DEFAULT_PAGE_TITLE = "Hope Investments";

const PAGE_TITLES = {
  REPORTS: `Reports | ${DEFAULT_PAGE_TITLE}`,
  PROFILE: `Profile | ${DEFAULT_PAGE_TITLE}`,
};

export { THEME_NAMES, THEMES_MAP, REGEX, PAGE_TITLES, DEFAULT_PAGE_TITLE };
