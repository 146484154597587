import React from "react";
import { Navigate } from "react-router-dom";

import useProfileStyles from "./index.styles";
import Header from "../../components/Header";
import Stack from "@mui/material/Stack";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Typography from "@mui/material/Typography";
import { useAuth } from "../../utils/AuthProvider";
import { DEFAULT_PAGE_TITLE, PAGE_TITLES } from "../../utils/constants";

const Profile = (props) => {
  const classes = useProfileStyles();

  const { currentUser } = useAuth();

  React.useEffect(() => {
    document.title = PAGE_TITLES.PROFILE;
    return () => {
      document.title = DEFAULT_PAGE_TITLE;
    };
  }, []);

  if (!currentUser) return <Navigate to={"/login"} replace={"true"} />;

  return (
    <>
      <Header />
      <Stack alignItems={"center"} paddingTop={2}>
        <AccountCircleOutlinedIcon className={classes.accountAvatar} />
        <Typography variant={"h6"} paddingTop={1} paddingBottom={1}>
          {currentUser?.displayName ?? currentUser?.email}
        </Typography>
      </Stack>
    </>
  );
};

export default Profile;
