import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

const useSignupStyles = makeStyles(({ spacing, palette }) => ({
  root: {},
  loginCard: {
    padding: spacing(1.5, 1),
    position: "absolute",
    width: "clamp(230px, 90%, 370px)",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    "& form": {
      display: "flex !important",
      flexDirection: "column",
    },

    "& form > div": {
      marginBottom: spacing(0.5),
      "& > div": {
        backgroundColor:
          palette.mode === "dark"
            ? alpha(palette.primaryDark[900], 0.72)
            : "rgba(255,255,255,0.72)",
      },
      "&:nth-child(1) > div": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      "&:nth-child(2) > div": {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
  loginBtn: {
    fontSize: `16px !important`,
    padding: `${spacing(1)} !important`,
    marginTop: `${spacing(2)} !important`,
  },
  snkRoot: {
    flex: 1,
    background: `${palette.success.main} !important`,
  },
  snkMessage: {
    width: "100%",
    textAlign: "center",
    color: "white",
    fontWeight: 500,
  },
  complementMsg: {
    textAlign: "center",
    paddingTop: spacing(1),
    "& a": {
      color: palette.primary.main,
      fontWeight: 600,
      textDecoration: "underline",
    },
  },
}));

export default useSignupStyles;
