import { makeStyles } from "@mui/styles";
import { getMetaThemeColor } from "../../utils/theme";

const useReportsStyles = makeStyles(({ spacing, palette }) => ({
  root: {},
  year: {
    padding: spacing(0, 1),
    backdropFilter: "blur(20px)",
    background: getMetaThemeColor(palette.mode),
    fontWeight: "700 !important",
    fontSize: "16px !important",
    fontStyle: "italic",
    position: "sticky",
    top: 54,
    left: 0,
  },
  titlePipe: {
    position: "relative",
    top: -1,
  },
  filePdf: {
    fontSize: `150px !important`,
  },
  reportsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "1rem",
    gridAutoFlow: "row",
  },
  card: {
    background: "transparent !important",
  },
  "@media only screen and (min-width: 650px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  "@media only screen and (min-width: 900px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
  "@media only screen and (min-width: 1100px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(5, 1fr)",
    },
  },
  "@media only screen and (min-width: 1300px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(6, 1fr)",
    },
  },
  "@media only screen and (min-width: 1600px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(7, 1fr)",
    },
  },
  "@media only screen and (min-width: 1800px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(8, 1fr)",
    },
  },
  "@media only screen and (min-width: 1900px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(9, 1fr)",
    },
  },
  "@media only screen and (min-width: 2000px)": {
    reportsContainer: {
      gridTemplateColumns: "repeat(12, 1fr)",
    },
  },
}));

export default useReportsStyles;
