import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import Divider from "@mui/material/Divider";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import useHeaderStyles from "./index.styles";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../utils/AuthProvider";

const HeaderLeftDrawer = () => {
  const classes = useHeaderStyles();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const { currentUser, logout } = useAuth();

  const toggleDrawer = React.useCallback(() => {
    setOpenDrawer((openDrawer) => !openDrawer);
  }, []);

  return (
    <Box className={classes.headerDrawer}>
      <IconButton color="primary" aria-label="Open Menu" onClick={toggleDrawer}>
        <DragHandleRoundedIcon />
      </IconButton>
      <SwipeableDrawer
        anchor={"left"}
        elevation={0}
        onOpen={() => {}}
        open={openDrawer}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {!!currentUser && (
          <Stack alignItems={"center"} paddingTop={2} paddingBottom={1}>
            <AccountCircleOutlinedIcon className={classes.accountAvatar} />
            <Typography variant={"body2"} paddingTop={1} paddingBottom={1}>
              {currentUser?.displayName ?? currentUser?.email}
            </Typography>
          </Stack>
        )}
        {!!currentUser && (
          <Stack>
            <MenuList>
              <Divider className={classes.menuDivider} />
              <NavLink to={"/reports"}>
                <MenuItem
                  classes={{
                    root: classes.menuItemRoot,
                  }}
                >
                  <ListItemIcon>
                    <AssessmentRoundedIcon className={classes.assessmentIcon} />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    className={classes.listItemItext}
                  >
                    Reports
                  </ListItemText>
                </MenuItem>
              </NavLink>
              <Divider className={classes.menuDivider} />
            </MenuList>
          </Stack>
        )}

        {!currentUser && (
          <Stack marginTop={"auto"} spacing={1} padding={1}>
            <NavLink to={"/login"} className={classes.loginLink}>
              <Button
                variant="contained"
                className={classes.loginBtn}
                endIcon={<LoginRoundedIcon />}
              >
                SignIn
              </Button>
            </NavLink>
            <NavLink to={"/signup"} className={classes.loginLink}>
              <Button variant="contained" className={classes.loginBtn}>
                SignUp
              </Button>
            </NavLink>
          </Stack>
        )}
        {!!currentUser && (
          <Stack
            spacing={1}
            padding={1}
            paddingTop={2}
            direction={"row"}
            marginTop={"auto"}
          >
            <NavLink to={"/profile"} className={classes.loginLink}>
              <Button variant="contained" className={classes.profileBtn}>
                profile
              </Button>
            </NavLink>
            <Button
              color={"error"}
              onClick={logout}
              variant="outlined"
              className={classes.logoutBtn}
            >
              <Stack alignItems={"center"}>
                <LogoutRoundedIcon fontSize={"8px"} />
                <Typography fontSize={10}>Logout</Typography>
              </Stack>
            </Button>
          </Stack>
        )}
      </SwipeableDrawer>
    </Box>
  );
};

export default HeaderLeftDrawer;
