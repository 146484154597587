import React from "react";
import Typography from "@mui/material/Typography";
import StackedBarChartRoundedIcon from "@mui/icons-material/StackedBarChartRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import CurrencyBitcoinRoundedIcon from "@mui/icons-material/CurrencyBitcoinRounded";
import ElectricCarRoundedIcon from "@mui/icons-material/ElectricCarRounded";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

import useLandingPageStyles from "./index.styles";

const LandingPage = () => {
  const classes = useLandingPageStyles();

  return (
    <main className={classes.root}>
      <section className={classes.hero}>
        <div className={classes.heroContent}>
          <div className={classes.leftContentWrapper}>
            <div className={classes.heroLeftContent}>
              <Typography variant={"h1"} className={classes.heroTitle}>
                HOPE
              </Typography>
              <Typography className={classes.heroDesc}>
                Smart Investments &nbsp;&nbsp;
                <span className={classes.dProp}>&#8733;</span>
                &nbsp;&nbsp; Better Returns
              </Typography>
            </div>
          </div>
          <div className={classes.rightContent}>
            <StackedBarChartRoundedIcon
              color={"text"}
              className={classes.graphIcon}
            />
            <CurrencyRupeeRoundedIcon
              color={"text"}
              className={classes.rupeeIcon}
            />
            <CurrencyBitcoinRoundedIcon
              color={"text"}
              className={classes.bitcoinIcon}
            />
            <ElectricCarRoundedIcon
              color={"text"}
              className={classes.carIcon}
            />
            <SavingsRoundedIcon color={"text"} className={classes.saveIcon} />
            <AccountBalanceRoundedIcon
              color={"text"}
              className={classes.bankIcon}
            />
          </div>
        </div>
      </section>
      <section className={classes.hopeDetails}></section>
    </main>
  );
};

export default LandingPage;
