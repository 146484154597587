import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";

import useHeaderStyles from "./index.styles";
import { useAuth } from "../../utils/AuthProvider";

const HeaderMenu = () => {
  const classes = useHeaderStyles();
  const iconBtnRef = React.useRef();
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleToggleIsMenuOpen = React.useCallback(() => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  }, []);

  const handleRouteToProfile = React.useCallback(() => {
    setIsMenuOpen(false);
    navigate("/profile");
  }, [navigate]);

  const handleLogout = React.useCallback(async () => {
    setIsMenuOpen(false);
    await logout();
    navigate("/login");
  }, [logout, navigate]);

  return (
    <nav className={classes.headerMenu}>
      <ul className={classes.navList}>
        <li className={classes.navListItem}>
          {currentUser ? (
            <>
              <NavLink to={"/reports"} className={classes.navLink}>
                <Typography variant={"h6"} className={classes.navLinkText}>
                  Reports
                </Typography>
              </NavLink>
              <NavLink to={"/profile"} className={classes.navLink}>
                <Typography variant={"h6"} className={classes.navLinkText}>
                  Profile
                </Typography>
              </NavLink>
              <IconButton
                ref={iconBtnRef}
                color="primary"
                aria-label="Switch Theme"
                className={classes.profileIcon}
                onClick={handleToggleIsMenuOpen}
              >
                <PersonRoundedIcon />
              </IconButton>
            </>
          ) : (
            <>
              <NavLink to={"/login"} className={classes.navLink}>
                <Button endIcon={<LoginRoundedIcon />}>LogIn</Button>
              </NavLink>
              <NavLink to={"/signup"} className={classes.navLink}>
                <Button>SignUp</Button>
              </NavLink>
            </>
          )}
        </li>
      </ul>
      <Menu
        open={isMenuOpen}
        anchorEl={iconBtnRef.current}
        onClose={handleToggleIsMenuOpen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "hidden",
            mt: "3px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        <MenuItem onClick={handleRouteToProfile}>
          <NavLink to={"/profile"} style={{ display: "flex" }}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon
                fontSize="small"
                sx={{ color: "text.primary" }}
              />{" "}
            </ListItemIcon>
            Profile
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutRoundedIcon fontSize="small" color={"error"} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </nav>
  );
};

export default HeaderMenu;
