import React from "react";
import ReactDOM from "react-dom";

import useSplashScreenStyles from "./index.styles";
import Typography from "@mui/material/Typography";

const SplashScreen = () => {
  const classes = useSplashScreenStyles();

  const body = React.useMemo(() => document.querySelector("body"), []);

  return ReactDOM.createPortal(
    <div className={classes.root}>
      <Typography variant={"h1"} className={classes.hopeTitle}>
        HOPE
      </Typography>
    </div>,
    body
  );
};

export default SplashScreen;
