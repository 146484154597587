import * as React from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import GlobalStyles from "@mui/material/GlobalStyles";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarContent from "@mui/material/SnackbarContent";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import Stack from "@mui/material/Stack";

import useSignupStyles from "./index.styles";
import Header from "../../components/Header";
import { TextField } from "@mui/material";
import { useAuth } from "../../utils/AuthProvider";
import Typography from "@mui/material/Typography";

const Signup = (props) => {
  const classes = useSignupStyles();

  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { signup, currentUser } = useAuth();
  const navigate = useNavigate();

  const handleOnSubmit = React.useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      const email = event.target.email.value;
      const password = event.target.password.value;
      try {
        setError("");
        setLoading(true);
        await signup(email, password);
        navigate("/", { replace: true });
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    },
    [signup, navigate]
  );

  if (currentUser) return <Navigate to={"/"} replace={true} />;

  return (
    <Box className={classes.root}>
      <GlobalStyles
        styles={{
          body: {
            position: "relative",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
          },
        }}
      />
      <Header />
      <Card variant="outlined" className={classes.loginCard}>
        <form onSubmit={handleOnSubmit}>
          <TextField
            placeholder={"Email"}
            type={"email"}
            autoFocus={true}
            name={"email"}
          />
          <TextField
            placeholder={"Password"}
            type={"password"}
            name={"password"}
          />
          <Button
            disabled={loading}
            type={"submit"}
            variant={"contained"}
            className={classes.loginBtn}
            endIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <LoginRoundedIcon />
              )
            }
          >
            SignUp!
          </Button>
        </form>
        <Typography variant={"body2"} className={classes.complementMsg}>
          Already have account? <NavLink to={"/login"}>Login</NavLink>
        </Typography>
      </Card>
      <Snackbar
        open={false}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        autoHideDuration={1000}
        onClose={() => {}}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          message={
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography>Successfully LoggedIn!</Typography>
              <TaskAltRoundedIcon />
            </Stack>
          }
          classes={{ root: classes.snkRoot, message: classes.snkMessage }}
        />
      </Snackbar>
    </Box>
  );
};

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default Signup;
