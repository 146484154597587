import React from "react";
import { Navigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import useReportsStyles from "./index.styles";
import { useAuth } from "../../utils/AuthProvider";
import Header from "../../components/Header";
import { DEFAULT_PAGE_TITLE, PAGE_TITLES } from "../../utils/constants";
import { allReports } from "../../utils";

const Reports = (props) => {
  const classes = useReportsStyles();
  const { currentUser } = useAuth();

  React.useEffect(() => {
    document.title = PAGE_TITLES.REPORTS;
    return () => {
      document.title = DEFAULT_PAGE_TITLE;
    };
  }, []);

  if (!currentUser) return <Navigate to="/login" replace={true} />;

  return (
    <>
      <Header
        title={
          <>
            HOPE <span className={classes.titlePipe}>|</span> Reports
          </>
        }
      />
      {Object.keys(allReports).map((year) => {
        return (
          <>
            <Typography variant={"h6"} className={classes.year} key={year}>
              {year}
            </Typography>
            <Box
              padding={1}
              className={classes.reportsContainer}
              key={`year-reports`}
            >
              {allReports[year].reports.map((report) => (
                <a
                  download={true}
                  target={"_blank"}
                  href={report.downloadURL}
                  key={report.downloadURL}
                  rel="noreferrer"
                >
                  <Card
                    variant={"outlined"}
                    border={"1px solid"}
                    key={report.downloadURL}
                    className={classes.card}
                  >
                    <Stack alignItems={"center"} justifyContent={"center"}>
                      <Box>
                        <InsertDriveFileRoundedIcon
                          className={classes.filePdf}
                        />
                      </Box>
                    </Stack>
                    <Divider />
                    <Stack
                      padding={1}
                      direction={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography variant={"body2"} fontWeight={600}>
                        {report.name}
                      </Typography>
                      <CloudDownloadRoundedIcon />
                    </Stack>
                  </Card>
                </a>
              ))}
            </Box>
          </>
        );
      })}
    </>
  );
};

export default Reports;
