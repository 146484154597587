import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

const useHeaderStyles = makeStyles(
  ({ spacing, palette, transitions, zIndex, breakpoints }) => ({
    root: {
      position: "sticky",
      top: 0,
      transition: transitions.create("top"),
      padding: spacing(1),
      zIndex: zIndex.appBar,
      backdropFilter: "blur(20px)",
      boxShadow: `inset 0px -1px 1px ${
        palette.mode === "dark" ? palette.primaryDark[700] : palette.grey[100]
      }`,
      backgroundColor:
        palette.mode === "dark"
          ? alpha(palette.primaryDark[900], 0.72)
          : "rgba(255,255,255,0.72)",
      display: "flex",
      alignItems: "center",
    },
    companyTitle: {
      fontSize: "16px !important",
      marginLeft: `${spacing(1)} !important`,
      marginRight: "auto !important",
    },
    themeBtnRoot: {},
    drawerPaper: {
      width: "60%",
      maxWidth: 350,
    },
    headerDrawer: {
      display: "block",
      [breakpoints.up("lg")]: {
        display: "none",
      },
    },
    accountAvatar: {
      fontSize: `50px !important`,
    },
    menuItemRoot: {
      minHeight: "auto !important",
      padding: `${spacing(1)} !important`,
    },
    menuDivider: {
      margin: `0 !important`,
    },
    drawerContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    drawerFooter: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    loginLink: {
      width: "100%",
      display: "block",
      marginTop: "auto",
    },
    loginBtn: {
      width: "100%",
    },
    profileBtn: {
      padding: `${spacing(1)} !important`,
      width: "100%",
    },
    assessmentIcon: {
      color:
        palette.mode === "dark" ? palette.grey[100] : palette.primaryDark[700],
    },
    listItemItext: {
      fontWeight: 500,
    },
    headerMenu: {
      display: "block",
      [breakpoints.down("lg")]: {
        display: "none",
      },
    },
    navList: {
      marginRight: spacing(1),
    },
    navListItem: {
      display: "flex",
      alignItems: "center",
      gap: spacing(1),
    },
    navLink: {},
    navLinkText: {
      fontSize: `13px !important`,
      fontWeight: "800 !important",
    },
    profileIcon: {
      marginLeft: `${spacing(1)} !important`,
    },
  })
);

export default useHeaderStyles;
